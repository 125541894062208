<template>
  <div>
    <vx-page-title-actions>
      <vx-button
        :can="canCreate"
        variant="primary"
        :to="createMissionPath"
      >
        Add
      </vx-button>
      <span v-if="isDragged" class="ml-1">
        <vx-button
          class="mr-1"
          variant="success"
          :loading="loading"
          @click="updatePositions"
        >
          Apply
        </vx-button>
        <vx-button
          variant="danger"
          @click="cancelPositions"
        >
          Discard
        </vx-button>
      </span>
    </vx-page-title-actions>
    <vx-table
      ref="tableRef"
      :resource="resource"
      :columns="columns"
      :filters="filters"
      sortable
      :reset-sortable-cache="resetSortableCache"
      @sortableCallback="onDragged"
    />
  </div>
</template>

<script>
import {
  VxTable,
  VxCellButtons,
  VxCellLink,
  VxCellBadges,
  VxCellDraggable,
  VxCellDatetimeRange
} from '@/components/table'
import { boolStatuses, missions, missionTypes, passDataToResource, useResource } from '@/services/resources'
import { filterTypes } from '@/services/table'
import { boolStatusesOptions, boolStatusFormat } from '@/services/utils'
import VxPageTitleActions from '@/components/layout/VxPageTitleActions'
import { VxButton } from '@/components/buttons'
import { buildRoute, path } from '@/router'
import { buttons } from '@/components/table/vx-table-cells/vx-cell-button'
import { useDraggable } from '@/services/draggable'
import { ref } from '@vue/composition-api'

export default {
  name: 'Missions',
  components: {
    VxPageTitleActions,
    VxButton,
    VxTable,
    VxCellButtons,
    VxCellLink,
    VxCellDraggable,
    VxCellDatetimeRange
  },
  setup () {
    const tableRef = ref(null)
    const resource = passDataToResource(missions.getAll, {
      requestParams: { params: { sort_by: 'position' } }
    })
    const { can: canCreate } = useResource(missions.create)
    const createMissionPath = path.createMission

    const {
      isDragged,
      resetSortableCache,
      loading,
      onDragged,
      cancelPositions,
      updatePositions
    } = useDraggable(tableRef, missions.updateOrder, 'missions')

    const columns = [
      { key: 'position', component: VxCellDraggable },
      { key: 'id' },
      { key: 'title' },
      { key: 'type', component: VxCellBadges },
      {
        key: 'is_enabled',
        component: VxCellBadges,
        tdAttr: {
          colors: {
            [boolStatuses['1']]: 'success',
            [boolStatuses['0']]: 'warning'
          }
        },
        formatter: (isEnabled) => boolStatusFormat(isEnabled)
      },
      {
        key: 'activity_range',
        component: VxCellDatetimeRange,
        tdAttr: (value, key, item) => ({
          start: item.generated_at,
          finish: item.finished_at
        })
      },
      {
        key: 'actions',
        component: VxCellButtons,
        tdAttr: (value, key, item) => ({
          buttons: actionButtons(item)
        })
      }
    ]

    const actionButtons = ({ id, is_enabled }) => {
      const editButton = {
        ...buttons.edit,
        to: buildRoute(path.updateMission, { params: { id } })
      }

      const deleteButton = {
        ...buttons.delete,
        disabled: is_enabled,
        resource: passDataToResource(missions.delete, {
          requestParams: { urlParams: { id } }
        })
      }

      return [editButton, deleteButton]
    }

    const filters = [
      {
        key: 'id',
        type: filterTypes.number
      },
      {
        key: 'title',
        type: filterTypes.text
      },
      {
        key: 'prize_id',
        type: filterTypes.number
      },
      {
        key: 'type',
        type: filterTypes.select,
        options: Object.values(missionTypes)
      },
      {
        key: 'is_enabled',
        type: filterTypes.select,
        options: boolStatusesOptions,
        optionsLabel: 'label',
        reduce: ({ value }) => value
      }
    ]

    return {
      resource,
      columns,
      filters,
      canCreate,
      createMissionPath,

      tableRef,
      onDragged,
      isDragged,
      loading,
      cancelPositions,
      updatePositions,
      resetSortableCache
    }
  }
}
</script>
